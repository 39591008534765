<template>
  <div class="list-user">
    <div class="list-user-table">
      <div class="mb-2 row p-0 admin_header">
        <div class="d-flex p-0">
          <div class="text-truncate-mess header-font font-weight-bold">
            アクセス中のユーザー
          </div>
        </div>
        <div
          class="tex-left text-md-right text-sm-right admin_right"
          style="margin-left: auto"
        >
          <button
            v-on:click="gotoDashboard()"
            class="float-right button-back-dashboard"
          >
            戻る
          </button>
        </div>
      </div>
      <Tables
        :items="listActivity.data"
        :fields="fields"
        :itemsPerPageSelect="{}"
        class="cpx-15"
      >
        <template v-slot:page_content="{ item }">
          <td v-html="item.page_content"></td>
        </template>
        <template v-slot:id="{ index }">
          <td>
            {{
              (listActivity.current_page - 1) * listActivity.per_page +
              index +
              1
            }}
          </td>
        </template>
        <template v-slot:name="{ item }">
          <td>
            {{
              item.last_name || item.first_name
                ? item.last_name + item.first_name
                : "未記入"
            }}
          </td>
        </template>
        <template v-slot:url="{ item }">
          <td style="word-break: break-all; min-width: 250px">
            {{ item.url }}
          </td>
        </template>
        <template v-slot:time="{ item }">
          <td style="min-width: 150px !important">
            {{ getTime(item, listActivity.data) }}
          </td>
        </template>
      </Tables>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listActivity.total"
      >
        <p class="font-weight-bold">
          {{ listActivity.total }}件中{{ listActivity.data.length }}件を表示
        </p>
      </div>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listActivity.last_page"
      >
        <CPagination
          v-if="listActivity.total"
          :activePage.sync="page"
          :pages="listActivity.last_page"
          align="center"
        />
        <div
          class="custom-selectLimitPage"
          v-if="!(listActivity.last_page == 1 && listActivity.data.length == 0)"
        >
          <b-form-select
            v-model="limit"
            tag-placeholder="Add this as new category"
            :options="listLimitRecordPage"
            @change="changeLimitPage()"
          ></b-form-select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tables from "../../components/common/Tables";
import { tableFields } from "../../utils/table-fields.js";
import { Constants } from "../../utils/constants";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import $ from "jquery";
import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "@fortawesome/fontawesome-free/css/regular.css";
import "@fortawesome/fontawesome-free/css/solid.css";
import { Api } from "../../utils/http-common";
import { mapGetters } from "vuex";

$.extend(true, $.fn.datetimepicker.defaults, {
  icons: {
    time: "far fa-clock",
    date: "far fa-calendar",
    up: "fas fa-arrow-up",
    down: "fas fa-arrow-down",
    previous: "fas fa-chevron-left",
    next: "fas fa-chevron-right",
    today: "fas fa-calendar-check",
    clear: "far fa-trash-alt",
    close: "far fa-times-circle",
  },
});
export default {
  name: "ListRealtimeActivity",
  data() {
    return {
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      fields: tableFields.REALTIME_ACTIVITY,
      listLimitRecordPage: [
        { value: 10, text: "10" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 200, text: "200" },
      ],
      limit: 10,
      page: 1,
      listActivity: {},
    };
  },
  components: {
    Tables,
  },
  created() {
    this.getListActivity(1);
  },
  computed: {
    ...mapGetters(["message", "success", "error"]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    page() {
      this.getListActivity(this.page);
    },
  },
  methods: {
    getListActivity(page) {
      console.log(1);
      const request = {
        shop_id: this.shop_id,
        page: page,
        limit: this.limit,
      };
      const api = Api.adminRequestServer;
      api
        .post("/realtime-activity/list", request)
        .then((response) => {
          if (response.status == 401) {
            this.redirectLoginShop();
          }
          const { data } = response;
          if (data.success) {
            this.listActivity = data.data;
          } else {
            this.listActivity = {};
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.redirectLoginShop();
          } else {
            console.log(error);
          }
        });
    },
    redirectLoginAdmin() {
      const extConstant = this.$route.currentRoute.params.shopId
        ? "_" + Constants.DOMAIN + "_" + this.$route.currentRoute.params.shopId
        : "_" + Constants.DOMAIN;
      localStorage.removeItem(Constants.TOKEN_ADMIN);
      localStorage.removeItem(Constants.USER_TYPE_ADMIN);
      localStorage.removeItem(Constants.SHOP_NAME);
      localStorage.removeItem(Constants.ADMIN_USER_INFO);
      localStorage.removeItem(Constants.EXPIRES_AT_ADMIN);
      localStorage.removeItem(Constants.EMAIL_ADMIN);
      localStorage.removeItem(Constants.TOKEN_ADMIN + extConstant);
      localStorage.removeItem(Constants.USER_TYPE_ADMIN + extConstant);
      localStorage.removeItem(Constants.SHOP_NAME + extConstant);
      localStorage.removeItem(Constants.ADMIN_USER_INFO + extConstant);
      localStorage.removeItem(Constants.EXPIRES_AT_ADMIN + extConstant);
      localStorage.removeItem(Constants.IS_REMEMBER_ADMIN);
      localStorage.removeItem(Constants.EMAIL_ADMIN + extConstant);
      this.$route.push({
        name: this.$route.currentRoute.params.shopId
          ? "login admin"
          : "login admin domain",
      });
    },
    getTime(item, records) {
      // recordsが存在するか確認
      if (!records || !Array.isArray(records)) {
        return 'データが不足しています';
      }

      // ユーザーごとにレコードをグループ化
      const groupedByUser = {};

      records.forEach(record => {
        if (!groupedByUser[record.user_id]) {
          groupedByUser[record.user_id] = [];
        }
        groupedByUser[record.user_id].push(record);
      });

      // ユーザーのレコードを時系列でソート
      const userRecords = groupedByUser[item.user_id];
      if (!userRecords) {
        return 'データが不足しています';
      }

      userRecords.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

      // 現在のレコードのインデックスを取得
      const currentIndex = userRecords.findIndex(record => record === item);

      let elapsedTimeMs;
      if (currentIndex === userRecords.length - 1) {
        // 最新のレコードの場合
        const now = new Date();
        elapsedTimeMs = now - new Date(item.created_at);
      } else {
        // 次のレコードとの経過時間を計算
        const nextRecord = userRecords[currentIndex + 1];
        elapsedTimeMs = new Date(nextRecord.created_at) - new Date(item.created_at);
      }

      const elapsedMinutes = Math.floor(elapsedTimeMs / 60000); // ミリ秒を分に変換
      const elapsedSeconds = Math.floor((elapsedTimeMs % 60000) / 1000); // 残りのミリ秒を秒に変換
      return `${elapsedMinutes}分${elapsedSeconds}秒`;
    },
    changeLimitPage() {
      this.getListActivity(1);
    },
    gotoDashboard() {
      if (this.$route.params.shopId) {
        this.$router.push({ name: "DashboardAdminBox" });
      } else {
        this.$router.push({ name: "DashboardAdminBox domain" });
      }
    },
  },
};
</script>
